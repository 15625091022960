export const SITE_CONFIG = {
    company: {
        name: 'ConvSol',
        tagline: 'AI Solutions for Modern Business',
        description: 'We specialize in developing cutting-edge AI solutions that transform businesses and drive innovation.',
        email: 'info@convsol.com',
        phone: '+1 (555) 123-4567',
        address: '123 AI Street, Tech City, TC 12345',
        linkedin: 'https://www.linkedin.com/company/convsol'
    },
    navigation: {
        items: [
            { name: 'intro', label: 'About', type: 'scroll' },
            { name: 'services', label: 'Services', type: 'scroll' },
            { name: 'contact', label: 'Contact', type: 'modal' }
        ],
        scrollOffset: -80,
        scrollDuration: 500
    },
    animations: {
        fadeIn: {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            transition: { duration: 0.5 }
        },
        slideUp: {
            initial: { y: 20, opacity: 0 },
            animate: { y: 0, opacity: 1 },
            transition: { duration: 0.5 }
        },
        slideIn: {
            initial: { x: -20, opacity: 0 },
            animate: { x: 0, opacity: 1 },
            transition: { duration: 0.5 }
        }
    },
    colors: {
        primary: '#1a56db',
        secondary: '#4f46e5',
        accent: '#3b82f6',
        text: {
            primary: '#1f2937',
            secondary: '#4b5563',
            light: '#9ca3af'
        },
        background: {
            primary: '#ffffff',
            secondary: '#f3f4f6',
            dark: '#111827'
        }
    },
    breakpoints: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px'
    }
}; 