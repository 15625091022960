import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
// import { Col, Container, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import NavBar from './Navbar/NavBar';
import ContactModal from './ContactModal';
import Stats from './Stats';
import GoogleAnalytics from './Analytics/GoogleAnalytics';
import { ANALYTICS_CONFIG } from '../config/analytics';

const Hero = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    useEffect(() => {
        const loadParticlesJS = async () => {
          const particlesScript = document.createElement('script');
          particlesScript.src =
            'https://cdnjs.cloudflare.com/ajax/libs/particles.js/2.0.0/particles.min.js';
          particlesScript.async = true;
          document.body.appendChild(particlesScript);
    
          particlesScript.onload = () => {
            window.particlesJS('particles-js', {
              particles: {
                number: {
                  value: 100,
                  density: {
                    enable: true,
                    value_area: 700,
                  },
                },
                color: {
                  value: '#1E3A8A',
                },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000',
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.5,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 5,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: '#1E3A8A',
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: 'none',
                  random: false,
                  straight: false,
                  out_mode: 'out',
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onhover: {
                    enable: true,
                    mode: 'repulse',
                  },
                  onclick: {
                    enable: true,
                    mode: 'push',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 100,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
              config_demo: {
                hide_card: false,
                background_color: '#1E3A8A',
                background_image: '',
                background_position: '50% 50%',
                background_repeat: 'no-repeat',
                background_size: 'cover',
              },
            });
          };
        };
    
        loadParticlesJS();
    
        // Clean up the script tag when the component is unmounted
        return () => {
          const particlesScript = document.querySelector('script[src*="particles.min.js"]');
          if (particlesScript) {
            document.body.removeChild(particlesScript);
          }
        };
      }, []);
    
    useEffect(() => {
        // Track page view and time on page
        if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.pageViews) {
            window.gtag('event', 'page_view', {
                page_title: 'Home',
                page_location: window.location.href,
                page_path: window.location.pathname
            });
        }

        // Track scroll depth
        if (ANALYTICS_CONFIG.googleAnalytics.trackingOptions.scrollDepth) {
            let maxScroll = 0;
            const handleScroll = () => {
                const scrollPercent = Math.round((window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100);
                if (scrollPercent > maxScroll) {
                    maxScroll = scrollPercent;
                    if (window.gtag) {
                        window.gtag('event', 'scroll_depth', {
                            'event_category': 'engagement',
                            'event_label': `Scrolled ${scrollPercent}%`
                        });
                    }
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);
    
    const trackButtonClick = (buttonName) => {
        if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.buttonClicks) {
            window.gtag('event', 'button_click', {
                'event_category': 'engagement',
                'event_label': buttonName
            });
        }
    };
    
    return (
        <>
            <GoogleAnalytics measurementId={ANALYTICS_CONFIG.googleAnalytics.measurementId} />
            <header className="hero relative min-h-screen w-full" id='hero' role="banner">
                <div id="particles-js" className="absolute inset-0 w-full h-full"></div>
                <div className="relative z-10 flex flex-col min-h-screen w-full">
                    <NavBar />
                    <main className="flex-grow flex items-center justify-center w-full">
                        <div className="container mx-auto px-4 py-12 w-full">
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8 }}
                                className="text-center w-full max-w-4xl mx-auto"
                            >
                                <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.2 }}
                                    className="inline-block bg-blue-900/10 text-blue-900 px-4 py-2 rounded-full text-sm font-semibold mb-6"
                                >
                                    Expert Conversational AI Development
                                </motion.span>
                                <motion.h1
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                    className="font-bold text-blue-900 mt-4 text-4xl md:text-5xl lg:text-6xl leading-tight px-4"
                                >
                                    Transform Your Business with Advanced AI Agents & Chatbots
                                </motion.h1>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                    className="text-lg text-gray-700 tracking-tight mt-6 px-4"
                                >
                                    <p className="mb-6">
                                        Leading provider of custom conversational AI solutions, intelligent chatbots, and agentic systems. We leverage cutting-edge LLM technology to revolutionize customer interactions and automate business processes.
                                    </p>
                                    <ul className="text-center space-y-3 max-w-2xl mx-auto">
                                        <li className="flex items-center justify-center">
                                            <svg className="w-5 h-5 text-blue-900 mr-3" fill="currentColor" viewBox="0 0 20 20">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                                            </svg>
                                            Custom AI Agents & Intelligent Chatbots
                                        </li>
                                        <li className="flex items-center justify-center">
                                            <svg className="w-5 h-5 text-blue-900 mr-3" fill="currentColor" viewBox="0 0 20 20">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                                            </svg>
                                            Advanced LLM Technology Integration
                                        </li>
                                        <li className="flex items-center justify-center">
                                            <svg className="w-5 h-5 text-blue-900 mr-3" fill="currentColor" viewBox="0 0 20 20">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                                            </svg>
                                            Business Process Automation
                                        </li>
                                    </ul>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                    className="flex flex-col sm:flex-row justify-center gap-4 mt-8 px-4"
                                >
                                    <button 
                                        onClick={() => {
                                            setIsContactModalOpen(true);
                                            trackButtonClick('contact_us');
                                        }}
                                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg font-semibold shadow-xl rounded-2xl transition-all duration-300 hover:shadow-2xl hover:scale-105 w-full sm:w-auto"
                                        aria-label="Start Your AI Journey"
                                    >
                                        Start Your AI Journey
                                    </button>
                                    <ScrollLink
                                        to="services"
                                        smooth={true}
                                        duration={500}
                                        offset={-80}
                                        onClick={() => trackButtonClick('explore_services')}
                                        className="text-blue-900 bg-white hover:bg-gray-50 inline-flex items-center justify-center px-8 py-4 text-lg font-semibold shadow-xl rounded-2xl transition-all duration-300 hover:shadow-2xl hover:scale-105 w-full sm:w-auto cursor-pointer"
                                        aria-label="Explore AI Solutions"
                                    >
                                        Explore AI Solutions
                                    </ScrollLink>
                                </motion.div>
                            </motion.div>
                        </div>
                    </main>
                    <div className="w-full px-4 py-8 md:py-12">
                        <Stats />
                    </div>
                </div>
                <ContactModal 
                    isOpen={isContactModalOpen} 
                    onClose={() => setIsContactModalOpen(false)} 
                />
            </header>
        </>
    );
};

export default Hero;