import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import WorkInProgress from './pages/WorkInProgress';
// import BlogPage from './pages/BlogPage';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Services from './components/Services';
// import FullArticlePage from './pages/FullArticlePage';
import './blogs/styles/BlogPage.css';
// import Teams from './pages/Teams';
import DemoProduct from './pages/DemoProduct';
import GoogleAnalytics from './components/Analytics/GoogleAnalytics';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("ConvSol - Where Chat Meets AI ");

  return (
    <>
      <Router>
        <GoogleAnalytics />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/workinprogress" element={<WorkInProgress />} />
            {/* <Route path="/team" element={<Teams />} /> */}
            {/* <Route path="/blogs" element={<BlogPage />} />
            <Route path="/blogs/:articleSlug" element={<FullArticlePage />} /> */}
            <Route path="/services" element={<Services />} />
            <Route path="/demo" element={<DemoProduct />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
