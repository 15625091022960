import { ANALYTICS_CONFIG } from '../config/analytics';

// Track form interactions
export const trackFormInteraction = (formName, action) => {
    if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.formInteractions) {
        window.gtag('event', 'form_interaction', {
            'event_category': 'Form',
            'event_action': action,
            'event_label': formName
        });
    }
};

// Track outbound links
export const trackOutboundLink = (url) => {
    if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.outboundLinks) {
        window.gtag('event', 'outbound_link', {
            'event_category': 'Outbound',
            'event_label': url,
            'transport_type': 'beacon'
        });
    }
};

// Track file downloads
export const trackFileDownload = (fileName, fileType) => {
    if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.fileDownloads) {
        window.gtag('event', 'file_download', {
            'event_category': 'Download',
            'event_label': fileName,
            'file_type': fileType
        });
    }
};

// Track video engagement
export const trackVideoEngagement = (videoName, action, value) => {
    if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.videoEngagement) {
        window.gtag('event', 'video_engagement', {
            'event_category': 'Video',
            'event_action': action,
            'event_label': videoName,
            'value': value
        });
    }
};

// Track errors
export const trackError = (errorMessage, errorSource) => {
    if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.errorTracking) {
        window.gtag('event', 'error', {
            'event_category': 'Error',
            'event_label': errorMessage,
            'error_source': errorSource
        });
    }
};

// Track user timing
export const trackTiming = (category, variable, value) => {
    if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.userTiming) {
        window.gtag('event', 'timing_complete', {
            'event_category': category,
            'name': variable,
            'value': value
        });
    }
};

// Set custom dimensions
export const setCustomDimension = (dimension, value) => {
    if (window.gtag && ANALYTICS_CONFIG.googleAnalytics.trackingOptions.customDimensions[dimension]) {
        window.gtag('set', ANALYTICS_CONFIG.googleAnalytics.trackingOptions.customDimensions[dimension], value);
    }
};

// Track goal completion
export const trackGoal = (goalName) => {
    const goal = ANALYTICS_CONFIG.googleAnalytics.goals[goalName];
    if (window.gtag && goal) {
        window.gtag('event', goal.action, {
            'event_category': goal.category,
            'event_label': goal.label
        });
    }
}; 