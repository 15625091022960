import { useEffect } from 'react';

const GoogleAnalytics = ({ measurementId }) => {
    useEffect(() => {
        if (measurementId) {
            // Create and load the Google Analytics script
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
            script.async = true;
            document.head.appendChild(script);

            // Initialize Google Analytics
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', measurementId);

            // Cleanup
            return () => {
                document.head.removeChild(script);
            };
        }
    }, [measurementId]);

    return null;
};

export default GoogleAnalytics; 