import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ContactModal from '../ContactModal';
import { SITE_CONFIG } from '../../constants/config';

const NavLinks = ({ mobile, onClose }) => {
    const [clickedLink, setClickedLink] = useState(null);
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    const handleLinkClick = (linkName) => {
        setClickedLink(linkName);
        if (mobile && onClose) {
            onClose();
        }
    };

    const handleContactClick = () => {
        setIsContactModalOpen(true);
        if (mobile && onClose) {
            onClose();
        }
    };

    const linkClasses = mobile 
        ? "nav-link fw-semibold px-4 py-3 rounded-lg text-lg text-gray-700 hover:text-blue-900 hover:bg-gray-50/50 transition-all duration-200"
        : "nav-link fw-semibold px-4 py-2 rounded-lg text-base text-gray-700 hover:text-blue-900 hover:bg-gray-50/50 transition-all duration-200";

    return (
        <>
            {SITE_CONFIG.navigation.items.map((item) => (
                item.type === 'scroll' ? (
                    <ScrollLink
                        key={item.name}
                        to={item.name}
                        className={`${linkClasses} cursor-pointer ${clickedLink === item.name ? 'text-blue-900 bg-gray-50/50' : ''}`}
                        smooth={true}
                        duration={SITE_CONFIG.navigation.scrollDuration}
                        offset={SITE_CONFIG.navigation.scrollOffset}
                        onClick={() => handleLinkClick(item.name)}
                        role="menuitem"
                        aria-label={`Scroll to ${item.label} section`}
                    >
                        {item.label}
                    </ScrollLink>
                ) : (
                    <button
                        key={item.name}
                        onClick={handleContactClick}
                        className={`${linkClasses} cursor-pointer ${clickedLink === item.name ? 'text-blue-900 bg-gray-50/50' : ''}`}
                        aria-label="Open contact form"
                        role="menuitem"
                    >
                        {item.label}
                    </button>
                )
            ))}

            <a
                href={SITE_CONFIG.company.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit ConvSol LinkedIn profile"
                className={`${linkClasses} d-flex align-items-center`}
                role="menuitem"
            >
                <FontAwesomeIcon icon={faLinkedin} className="w-5 h-5" aria-hidden="true" />
            </a>

            <ContactModal 
                isOpen={isContactModalOpen} 
                onClose={() => setIsContactModalOpen(false)} 
            />
        </>
    );
};

export default NavLinks;



