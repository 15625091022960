import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { motion } from 'framer-motion';
import NavLinks from './NavLinks';
import ContactModal from '../ContactModal';
// import convlogo from '../../images/ConVSOL.png'

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Navbar 
                expand="lg" 
                fixed="top"
                className={`navbar navbar-expand-lg transition-all duration-300 ${
                    scrolled ? 'bg-white/90 backdrop-blur-sm shadow-lg py-2' : 'bg-transparent py-3'
                }`}
            >
                <Container className="px-4">
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Navbar.Brand href="/" className="text-blue-900 fw-bold fs-2">
                            ConvSol
                        </Navbar.Brand>
                    </motion.div>

                    <Navbar.Toggle 
                        aria-controls="basic-navbar-nav" 
                        onClick={() => setIsOpen(!isOpen)}
                        className="border-0"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto align-items-center">
                            <NavLinks mobile={false} />
                        </Nav>
                    </Navbar.Collapse>
                </Container>

                {/* Mobile Menu */}
                <motion.div
                    initial={false}
                    animate={{ height: isOpen ? 'auto' : 0 }}
                    transition={{ duration: 0.3 }}
                    className="position-fixed top-0 start-0 w-100 bg-white/95 backdrop-blur-sm shadow-lg d-lg-none rounded-bottom-4"
                    style={{ marginTop: '72px', overflow: 'hidden' }}
                >
                    <div className="p-4">
                        <NavLinks mobile={true} onClose={() => setIsOpen(false)} />
                    </div>
                </motion.div>
            </Navbar>

            <ContactModal 
                isOpen={showContactModal} 
                onClose={() => setShowContactModal(false)} 
            />
        </>
    );
};

export default NavBar;
