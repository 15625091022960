import React from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { 
    FaRobot, 
    FaBrain, 
    FaCode, 
    FaCloud,
    FaCheckCircle
} from 'react-icons/fa';

const Build = () => {
    const features = [
        {
            title: "AI-Powered Solutions",
            description: "Leverage cutting-edge artificial intelligence to automate processes and enhance decision-making.",
            icon: <FaRobot className="w-8 h-8 text-blue-900" />
        },
        {
            title: "Machine Learning",
            description: "Implement sophisticated algorithms to analyze data and predict outcomes with high accuracy.",
            icon: <FaBrain className="w-8 h-8 text-blue-900" />
        },
        {
            title: "Custom Development",
            description: "Build tailored software solutions that perfectly align with your business objectives.",
            icon: <FaCode className="w-8 h-8 text-blue-900" />
        },
        {
            title: "Cloud Integration",
            description: "Seamlessly integrate cloud services for scalable and efficient operations.",
            icon: <FaCloud className="w-8 h-8 text-blue-900" />
        }
    ];

    return (
        <section className="py-16 bg-gray-50">
            <Container>
                <div className="text-center mb-12">
                    <motion.h2 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="text-3xl font-bold text-blue-900 mb-4"
                    >
                        Building the Future with AI
                    </motion.h2>
                    <motion.p 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        className="text-base text-secondary max-w-2xl mx-auto"
                    >
                        We combine cutting-edge technology with innovative solutions to help businesses thrive in the digital age.
                    </motion.p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.3 + index * 0.1 }}
                            className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-gray-100"
                        >
                            <div className="flex items-start space-x-4">
                                <div className="flex-shrink-0 p-3 bg-gray-50 rounded-lg">
                                    {feature.icon}
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold text-blue-900 mb-2">{feature.title}</h3>
                                    <p className="text-base text-secondary">{feature.description}</p>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>

                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.7 }}
                    className="mt-12 text-center"
                >
                    <h3 className="text-2xl font-bold text-blue-900 mb-6">Why Choose Us?</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex items-center space-x-3">
                            <FaCheckCircle className="w-5 h-5 text-blue-900 flex-shrink-0" />
                            <span className="text-base text-secondary">Expert Team</span>
                        </div>
                        <div className="flex items-center space-x-3">
                            <FaCheckCircle className="w-5 h-5 text-blue-900 flex-shrink-0" />
                            <span className="text-base text-secondary">Innovative Solutions</span>
                        </div>
                        <div className="flex items-center space-x-3">
                            <FaCheckCircle className="w-5 h-5 text-blue-900 flex-shrink-0" />
                            <span className="text-base text-secondary">24/7 Support</span>
                        </div>
                    </div>
                </motion.div>
            </Container>
        </section>
    );
};

export default Build;
