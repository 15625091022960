import React from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { 
    SiLangchain, 
    SiOpenai, 
    SiTensorflow, 
    SiPytorch, 
    SiReact, 
    SiNodedotjs,
    SiGraphql,
    SiFastapi
} from 'react-icons/si';
import img from '../images/about.jpg';

const Intro = () => {
    const technologies = [
        { 
            name: "LangChain", 
            icon: <SiLangchain className="w-8 h-8 text-blue-900" />
        },
        { 
            name: "LangGraph", 
            icon: <SiGraphql className="w-8 h-8 text-blue-900" />
        },
        { 
            name: "OpenAI", 
            icon: <SiOpenai className="w-8 h-8 text-blue-900" />
        },
        { 
            name: "FastAPI", 
            icon: <SiFastapi className="w-8 h-8 text-blue-900" />
        },
        { 
            name: "TensorFlow", 
            icon: <SiTensorflow className="w-8 h-8 text-blue-900" />
        },
        { 
            name: "PyTorch", 
            icon: <SiPytorch className="w-8 h-8 text-blue-900" />
        },
        { 
            name: "React", 
            icon: <SiReact className="w-8 h-8 text-blue-900" />
        },
        { 
            name: "Node.js", 
            icon: <SiNodedotjs className="w-8 h-8 text-blue-900" />
        }
    ];

    return (
        <>
            <section id="intro" className="py-16 bg-gray-50">
                <Container>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6 }}
                            className="relative"
                        >
                            <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden shadow-2xl">
                                <img 
                                    src={img} 
                                    alt="AI Solutions" 
                                    className="object-cover w-full h-full"
                                    loading="lazy"
                                />
                            </div>
                        </motion.div>
                        <div>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.2 }}
                            >
                                <h2 className="text-3xl font-bold text-blue-900 mb-6">Transforming Ideas into AI-Powered Solutions</h2>
                                <p className="text-base text-secondary">
                                    We specialize in developing cutting-edge AI solutions that help businesses automate processes, enhance customer experiences, and drive innovation. Our expertise spans across natural language processing, machine learning, and cloud-native applications.
                                </p>
                            </motion.div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="py-16 bg-white">
                <Container>
                    <div className="text-center mb-12">
                        <h2 className="text-2xl font-bold text-blue-900 mb-4">Our Technology Stack</h2>
                        <p className="text-base text-secondary max-w-2xl mx-auto">
                            We leverage cutting-edge technologies to build powerful AI solutions that drive business growth and innovation.
                        </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                        {technologies.map((tech, index) => (
                            <div
                                key={index}
                                className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-gray-100"
                            >
                                <div className="flex flex-col items-center text-center">
                                    <div className="mb-4 p-3 bg-gray-50 rounded-lg">{tech.icon}</div>
                                    <h4 className="text-sm font-semibold text-blue-900">{tech.name}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Intro;