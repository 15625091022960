import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import { useDocTitle } from '../components/CustomHook';

const Contact = () => {
    useDocTitle('ConvSol - Contact Us');

    return (
        <>
            <NavBar />
            <div className="min-h-screen bg-gray-50 py-24 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    <div className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Get in Touch
                        </h1>
                        <p className="text-lg text-gray-600">
                            We'd love to hear from you. Let us know how we can help with your AI solutions.
                        </p>
                    </div>
                    <div className="bg-white rounded-2xl shadow-xl p-8">
                        <ContactForm />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;