import React from 'react';
import { motion } from 'framer-motion';

const Stats = () => {
    const stats = [
        { number: "100%", label: "Client Satisfaction" },
        { number: "15+", label: "AI Solutions Deployed" },
        { number: "24/7", label: "Support Available" },
        { number: "10+", label: "Enterprise Clients" }
    ];

    return (
        <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 mt-12">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                {stats.map((stat, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className="text-center"
                    >
                        <div className="text-3xl font-bold text-blue-900 mb-2">{stat.number}</div>
                        <div className="text-sm text-gray-600">{stat.label}</div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Stats; 