import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { motion } from 'framer-motion';

const Footer = () => {
    const [clickedLink, setClickedLink] = useState(null);

    const handleLinkClick = (linkName) => {
        setClickedLink(linkName);
    };

    return (
        <footer className="bg-white border-t border-gray-200">
            <Container className="py-12">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Company Info */}
                    <div className="col-span-1 md:col-span-2">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                        >
                            <h3 className="text-3xl font-bold text-blue-900 mb-4">ConvSol</h3>
                            <p className="text-gray-600 mb-6">Where Chat Meets AI</p>
                            <div className="flex space-x-4">
                                <a
                                    href="https://www.linkedin.com/company/convsol"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="LinkedIn Profile"
                                    className="text-blue-900 hover:text-blue-800 transition-colors duration-200"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6" />
                                </a>
                            </div>
                        </motion.div>
                    </div>

                    {/* Quick Links */}
                    <div>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.2 }}
                        >
                            <h4 className="text-lg font-semibold text-blue-900 mb-4">Quick Links</h4>
                            <ul className="space-y-2">
                                <li>
                                    <HashLink to="/" className="text-gray-600 hover:text-blue-900 transition-colors duration-200">
                                        About
                                    </HashLink>
                                </li>
                                <li>
                                    <ScrollLink
                                        to="services"
                                        className={`text-gray-600 hover:text-blue-900 transition-colors duration-200 cursor-pointer ${
                                            clickedLink === 'services' ? 'text-blue-900' : ''
                                        }`}
                                        smooth={true}
                                        duration={500}
                                        onClick={() => handleLinkClick('services')}
                                    >
                                        Services
                                    </ScrollLink>
                                </li>
                            </ul>
                        </motion.div>
                    </div>

                    {/* Contact Info */}
                    <div>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            <h4 className="text-lg font-semibold text-blue-900 mb-4">Contact</h4>
                            <ul className="space-y-2">
                                <li className="text-gray-600">
                                    <a href="mailto:info@convsol.com" className="hover:text-blue-900 transition-colors duration-200">
                                        info@convsol.com
                                    </a>
                                </li>
                                <li className="text-gray-600">Islamabad, Pakistan</li>
                            </ul>
                        </motion.div>
                    </div>
                </div>

                {/* Copyright */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="mt-12 pt-8 border-t border-gray-200 text-center text-gray-600"
                >
                    <p>ConvSol &copy; {new Date().getFullYear()} - All rights reserved.</p>
                </motion.div>
            </Container>
        </footer>
    );
};

export default Footer;
