export const ANALYTICS_CONFIG = {
    // Replace this with your actual Google Analytics Measurement ID
    googleAnalytics: {
        measurementId: 'G-HXQ3VMR93B',
        // Configure which events to track
        trackingOptions: {
            pageViews: true,
            buttonClicks: true,
            scrollDepth: true,
            timeOnPage: true,
            // New tracking options
            formInteractions: true,
            outboundLinks: true,
            fileDownloads: true,
            videoEngagement: true,
            errorTracking: true,
            userTiming: true,
            customDimensions: {
                userType: 'dimension1',
                sessionSource: 'dimension2'
            }
        },
        goals: {
            contactForm: {
                category: 'Form',
                action: 'Submit',
                label: 'Contact Form'
            },
            serviceView: {
                category: 'Service',
                action: 'View',
                label: 'Service Details'
            }
        }
    }
}; 