import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
    FaRobot, 
    FaBrain, 
    FaCode, 
    FaCloud,
    FaCheckCircle
} from 'react-icons/fa';
import { Col, Container, Row, Nav, Tab } from 'react-bootstrap';
import ContactModal from './ContactModal';
// import { SITE_CONFIG } from '../constants/config';

const Service = () => {
    const [showContactModal, setShowContactModal] = useState(false);

    const services = [
        {
            title: "Conversational AI",
            description: "Elevate customer engagement with state-of-the-art Conversational AI. Our tailored chatbots, virtual assistants, and voice-enabled solutions deliver smooth, personalized interactions that transform customer experiences and drive value.",
            icon: <FaRobot className="w-12 h-12 text-blue-900" />,
            features: [
                "Intelligent Chatbots",
                "Virtual Assistants",
                "Voice-Enabled Solutions",
                "Multi-language Support",
                "Context-Aware Responses",
                "24/7 Customer Support"
            ]
        },
        {
            title: "Python Development",
            description: "Maximize versatility with custom Python solutions. Our team builds robust applications, from web platforms to data analysis tools, precisely tailored to meet your unique business demands.",
            icon: <FaCode className="w-12 h-12 text-blue-900" />,
            features: [
                "Web Applications",
                "Data Analysis Tools",
                "API Development",
                "Machine Learning Solutions",
                "Automation Scripts",
                "Custom Integrations"
            ]
        },
        {
            title: "DevOps and Cloud Solutions",
            description: "Optimize your digital landscape with advanced DevOps and cloud solutions. We streamline workflows, automate deployments, and secure applications, empowering your business with efficient, scalable, and collaborative cloud infrastructure.",
            icon: <FaCloud className="w-12 h-12 text-blue-900" />,
            features: [
                "Cloud Migration",
                "CI/CD Pipelines",
                "Infrastructure as Code",
                "Container Orchestration",
                "Monitoring & Logging",
                "Security Automation"
            ]
        },
        {
            title: "Natural Language Processing",
            description: "Unleash intelligent communication with our NLP solutions, enabling machines to understand and respond to human language accurately. Drive deeper insights and seamless interaction with data-driven NLP capabilities.",
            icon: <FaBrain className="w-12 h-12 text-blue-900" />,
            features: [
                "Text Analysis",
                "Sentiment Analysis",
                "Entity Recognition",
                "Language Translation",
                "Document Classification",
                "Custom NLP Models"
            ]
        }
    ];

    return (
        <div className="services py-20 bg-gradient-to-b from-white to-gray-50" id="services">
            <Container>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-center max-w-3xl mx-auto mb-16 px-4"
                >
                    <h1 className="text-3xl font-bold text-blue-900 mb-6">
                        Our Services
                    </h1>
                    <p className="text-base text-secondary">
                        Explore our comprehensive range of services tailored to meet your business needs.
                    </p>
                </motion.div>

                <Tab.Container id="services-tabs" defaultActiveKey="first">
                    <Row className="align-items-start">
                        <Col sm={12} md={7} className="mb-8 md:mb-0">
                            <Nav variant="pills" className="flex-column">
                                <Row className="g-3">
                                    {services.map((service, index) => (
                                        <Col sm={12} md={6} key={index}>
                                            <Nav.Item>
                                                <Nav.Link 
                                                    eventKey={index === 0 ? "first" : index === 1 ? "second" : index === 2 ? "third" : "fourth"}
                                                    className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 p-4 h-full"
                                                >
                                                    <div className="flex items-center space-x-4">
                                                        <div className="p-3 bg-gray-50 rounded-lg flex-shrink-0">
                                                            {service.icon}
                                                        </div>
                                                        <div className="min-w-0 flex-1">
                                                            <h3 className="text-base font-semibold text-blue-900 mb-1">{service.title}</h3>
                                                            <p className="text-sm text-gray-600 line-clamp-2">{service.description}</p>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Col>
                                    ))}
                                </Row>
                            </Nav>
                        </Col>
                        <Col sm={12} md={5}>
                            <Tab.Content>
                                {services.map((service, index) => (
                                    <Tab.Pane 
                                        key={index}
                                        eventKey={index === 0 ? "first" : index === 1 ? "second" : index === 2 ? "third" : "fourth"}
                                    >
                                        <motion.div
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ duration: 0.5 }}
                                            className="bg-white rounded-2xl p-6 md:p-8 shadow-xl h-full"
                                        >
                                            <div className="mb-6">
                                                <h2 className="text-2xl font-bold text-blue-900 mb-4">{service.title}</h2>
                                                <p className="text-base text-secondary break-words">{service.description}</p>
                                            </div>
                                            
                                            <div className="grid grid-cols-1 gap-4">
                                                {service.features.map((feature, idx) => (
                                                    <motion.div
                                                        key={idx}
                                                        initial={{ opacity: 0, y: 10 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: 0.3, delay: idx * 0.1 }}
                                                        className="flex items-start space-x-3"
                                                    >
                                                        <FaCheckCircle className="w-5 h-5 text-blue-900 flex-shrink-0 mt-1" />
                                                        <span className="text-base text-secondary break-words">{feature}</span>
                                                    </motion.div>
                                                ))}
                                            </div>
                                        </motion.div>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

            <ContactModal show={showContactModal} onHide={() => setShowContactModal(false)} />
        </div>
    );
};

export default Service;



    
