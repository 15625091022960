import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import ContactModal from './ContactModal';

const Cta = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    return (
        <section className="py-16 bg-gradient-to-r from-blue-900 to-blue-800">
            <Container>
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="bg-white rounded-2xl p-8 md:p-12 shadow-2xl"
                >
                    <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                        <div className="text-center md:text-left">
                            <h2 className="text-3xl font-bold text-blue-900 mb-4">
                                Ready to Transform Your Business?
                            </h2>
                            <p className="text-base text-secondary max-w-2xl">
                                Let's build something amazing together. Our team is ready to help you leverage the power of AI and automation.
                            </p>
                        </div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <button 
                                onClick={() => setIsContactModalOpen(true)}
                                className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-base font-semibold shadow-xl rounded-2xl transition-all duration-300 hover:shadow-2xl w-full md:w-auto"
                            >
                                Get Started Today
                                <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </motion.div>
                    </div>
                </motion.div>
            </Container>
            <ContactModal 
                isOpen={isContactModalOpen} 
                onClose={() => setIsContactModalOpen(false)} 
            />
        </section>
    );
};

export default Cta;